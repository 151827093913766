const selectors = {
  productCard: '[data-product-card]',
  productCardImage: '[data-product-card-image]',
  productCardVariantImage: '[data-product-card-variant-image]',
}

class ProductCard extends HTMLElement {
  constructor(element) {
    super();

    this.element = element;
    this.productCard = this.querySelectorAll(selectors.productCard);
    this.productCardImage = this.querySelector(selectors.productCardImage);
    this.productCardVariantImage = this.querySelectorAll(selectors.productCardVariantImage);

    this.updateProductImageOnHover();
  }

  updateProductImageOnHover() {
    this.productCardVariantImage.forEach(variantImage => {
      const variantImageSized = variantImage.src.replace('width=80', 'width=600')
      variantImage.addEventListener('mouseover', () => {
        this.productCardImage.src = variantImageSized;
      });
    });
  }
}

customElements.define('product-card', ProductCard);
